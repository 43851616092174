<template>
	<edit-template>

    <el-form ref="form" :model="form" :rules="rules" class="form-body" label-width="160rem" style="padding-top: 70rem;padding-left: 100rem">
			<el-form-item label="商品名称" prop="goods_name">
				<el-input v-model="form.goods_name" class="independentStyle" placeholder="请输入商品名称"></el-input>
			</el-form-item>
			<el-form-item label="商品类型" prop="goods_category">
				<el-select v-model="form.goods_category" placeholder="请选择上商品类型" @change="goodsCategoryChange">
					<el-option v-for="(item,index) in goodsCategory" :label="item" :value="index+''"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="收费方式" prop="goods_type">
				<el-select v-model="form.goods_type" placeholder="请选择收费方式" value="">
					<el-option label="一次收费" value="1"></el-option>
					<el-option label="多次收费" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item :label="`${form.goods_category === '0' ? '普高线上商品价格' : '商品价格'}`" prop="goods_price">
				<el-input v-model="form.goods_price" :maxlength="20" :placeholder="`${form.goods_category === '0' ? '请输入普高线上商品价格' : '请输入商品价格'}`"></el-input>
			</el-form-item>
			<el-form-item v-if="form.goods_category === '0'" label="普高线下商品价格" prop="goods_price_below">
				<el-input v-model="form.goods_price_below" :maxlength="20" placeholder="请输入普高线下商品价格"></el-input>
			</el-form-item>
		</el-form>
    <template slot="footer">
      <el-button type="primary" @click="onPreserve">保存</el-button>
      <el-button @click="onBack">取消</el-button>
      <el-button type="primary" @click="onLook">查看历史记录</el-button>
    </template>

    <el-dialog :before-close="()=>changeDialog = false" :visible.sync="changeDialog" width="30%">
      <span>
      <div v-for="item in list" v-if="list.length>0" class="history-box flex flex-between">
        <div class="flex flex-between" style="width: 10rem">
          <div class="step-box" style="padding-top: 16rem">
            <div class="cir"></div>
            <span class="cir-line"></span>
          </div>
        </div>
        <div style="width: calc(100% - 50rem);padding: 0 0 30rem">
          <p>{{ item.change_detail }}</p>
        </div>
      </div>
      <t-result v-if="!list.length" :type="'empty'"></t-result>
      </span>
    </el-dialog>

</edit-template>
</template>

<script>

import TResult from "@/components/Result.vue";

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  components: {TResult},

	data() { // 这里存放数据
    const validateNumber = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入商品价格'))
      }
      const str = value + ''
      const arr = str.split('.')
      if(arr.length > 1 && arr[1].length > 2) {
        return callback(new Error('最多输入2位小数'))
      }
      const num = Number(value)
      if(this.form.goods_category === '12') {
        if(num >= 1000000 || num <= -1000000) {
          return callback(new Error('商品价格限制为：-999999.99~999999.99元'))
        }
        return callback()
      }
      if(num < 0 || num >= 100000) {
        return callback(new Error('商品价格限制为：0~999999.99元'))
      }
      callback()
    }
		return {
			form: {
				goods_name: '',
				type: '',
				price: '',
				goods_price: '',
				goods_price_below: '',
			},
			changeDialog: false,
			list: [],
			rules: {
				goods_name: [
					{required: true, message: '请输入商品名称', trigger: 'change'},
					{min: 1, max: 20, message: '请输入1至20个字符', trigger: 'change'},
				],
				goods_type: [
					{required: true, message: '请选择收费方式', trigger: 'change'},
				],
				goods_category: [
					{required: true, message: '请选择商品类型', trigger: 'change'},
				],
				goods_price: [
          {required: true, validator: validateNumber, trigger: 'change'},
				],
				goods_price_below: [
					{required: true, validator: validateNumber, trigger: 'change'},
				],
			},
			goodsCategory: []
		};
	},

	mounted() {
		this.$_register.get('api/recruit/goods-manage/get-goods-category').then(res=>{
			this.goodsCategory = res.data.data
		})
	},

	methods: { // 方法合集
		onLook() {
			this.$_register.get('api/recruit/common/get-opt-history', {params: {tbl_name: 'goods', tbl_id: this.$route.query.id}}).then(res => {
				this.list = res.data.data.list
				this.changeDialog = true
			})
		},

		onBack() {
			this.$confirm('是否取消编辑', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.$router.back();
			});
		},
		onPreserve() {
			this.$refs.form.validate((valid) => {
				if (valid) {
					this.$_register.post('api/recruit/goods-manage/edit-goods', this.form).then(res => {
						if (res.data.status === 0) {
							this.$message.success('修改成功');
							this.$router.back();
						} else this.$message.error(res.data.msg)
					})
				}
			});
		},
		onChangePrice(v) {
			this.form.goods_price = v.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace(/^0\d[0-9]*/g, '').replace(/^(\d+)\.(\d\d).*$/, '$1.$2')
			// if (v < 42949672.95) this.form.goods_price = v.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace(/^0\d[0-9]*/g, '').replace(/^(\d+)\.(\d\d).*$/, '$1.$2')
			// else this.form.goods_price = '42949672.95'
		},
		onChangeGoodsPriceBelow(v) {
			this.form.goods_price_below = v.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace(/^0\d[0-9]*/g, '').replace(/^(\d+)\.(\d\d).*$/, '$1.$2')
		},
		goodsCategoryChange(val) {
			this.$refs.form.validateField('goods_price',()=>{})
		}
	},

	created() {
		this.$_register.get('api/recruit/goods-manage/goods-one?goods_id=' + this.$route.query.id).then(res => {
			this.form = res.data.data
			this.form.goods_id = this.form.id
			this.form.goods_price = this.form.goods_price + ''
			this.form.goods_type = this.form.goods_type + ''
			this.form.goods_category = this.form.goods_category + ''
		})
  }
};
</script>

<style scoped lang="scss">
.history-box {
  .step-box {
    position: relative;
  }

  .cir {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    width: 15rem;
    height: 15rem;
		transform: translateX(-50%);
		border-radius: 50%;
		background-color: #1C4EFD;
	}

	.cir-line {
		position: absolute;
		top: 15rem;
		left: 50%;
		width: 1rem;
		height: 100%;
		transform: translateX(-50%);
		background-color: #ccc;
	}

	.div1 > div {
		line-height: 66rem;
		height: 66rem;
		padding-right: 40rem;
		padding-left: 20rem;
		white-space: nowrap;
		word-break: keep-all;
		color: #333;
	}

	&:last-child {
		.cir-line {
			display: none;
		}
	}
}

@media only screen and (min-width: 1140rem) {
	.independentStyle {
		width: 30.13888888888888vw !important;
		/*min-width:250rem;*/
		max-width: 100%;
	}
}

@media only screen and (max-width: 1139rem) {
	.independentStyle {
		width: 500rem !important;
		max-width: 100%;
	}
}
</style>
